<template>
  <div id="productadv-css">
    <!-- 面包屑 -->
    <BaseBread style="margin-left: 5vh;">
      <BaseBreadItem to="/">首页</BaseBreadItem>
      <BaseBreadItem>解决方案</BaseBreadItem>
    </BaseBread>
    <div id="product-adv-top">
      <h1>行业解决方案</h1>
      <p>全心服务  智慧物流</p>
    </div>
    <el-divider/>
    <SolveDescribe></SolveDescribe>
  </div>
</template>
<script>
import {findAd} from '@/api/home'
import {ref} from 'vue'
import SolveInfo from "./components/solve-info.vue";
import BaseOneImage from "@/components/library/base-one-image.vue";
import SolveDescribe from "@/views/productadv/components/solve-describe.vue";
import BaseBreadItem from "@/components/library/base-bread-item.vue";
import BaseBread from "@/components/library/base-bread.vue";

// // 获取当前路由对象
// const route = useRoute();
// // 获取动态路由参数的name
// const name = route.query.name;

export default {
  name: 'HomeBanner',
  components: {BaseBread, BaseBreadItem, SolveDescribe, BaseOneImage, SolveInfo},
  setup() {
    const dataSource = ref([])
    const dataSource2 = ref([])
    findAd("htmlSolveImage").then(data => {
      dataSource.value = data.data.list[0]
    })
    findAd("htmlCaseImage").then(data => {
      dataSource2.value = data.data.list[0]
    })
    return {dataSource, dataSource2}
  }
}

</script>

<style scoped lang="less">
#productadv-css {
  background-color: #FFF;

  #product-adv-top {
    padding-left: 10vh;
    padding-bottom: 5vh;

    h1 {
      font-size: 40px;
      padding-bottom: 10px;
    }

    p {
      font-size: 20px;
      font-weight: 600;

    }
  }
}


//.text-split-line {
//  background: #fff;
//  display: flex;
//  align-items: center;
//  text-align: center;
//  height: 160px;
//  padding: 0 80px;
//
//  .info {
//    padding-left: 10px;
//    flex: 1;
//
//    p {
//      &:first-child {
//        font-size: 32px;
//        font-weight: bold;
//        margin-bottom: 18px;
//      }
//
//      &:last-child {
//        color: #999;
//        font-size: 16px;
//      }
//    }
//  }
//}
</style>
