<template>
  <div class="solution-box">
    <div v-for="solution in solutionList">
      <SolveDescribeContentLeft v-if="solution.num%2!==0" :solution="solution"/>
      <SolveDescribeContentRight v-else :solution="solution"/>
    </div>
  </div>
</template>

<script>
import SolveDescribeContentLeft from "@/views/productadv/components/solve-describe-content-left.vue";
import SolveDescribeContentRight from "@/views/productadv/components/solve-describe-content-right.vue";
import {ref} from "vue";
import {findProductAdvSolveList} from "@/api/productadv";

export default {
  components: {SolveDescribeContentRight, SolveDescribeContentLeft},
  setup() {
    const solutionList = ref([]);
    let params = {}
    findProductAdvSolveList(params).then(res => {
      solutionList.value = res.data
    })
    return {solutionList}
  },
}
</script>

<style lang='less' scoped>
.solution-box {
  div {
    width: 100%;
    max-height: 30vh;
    margin-top: 5vh;
    min-height: 400px;

  }
}
</style>
