<template>
  <div class="product-adv-body">
    <div>
      <!--   根据id可以点跳   -->
      <!--      <div class="product-adv-container" :id="solution.id">-->
      <div class="product-adv-container">
        <div class="product-adv-container-left">
          <div class="left-content animated fadeInLeft">
            <font-awesome-icon :icon="solution.icon" class="product-adv-info-icon"/>
            <h2>{{ solution.articleTitle }}</h2>
            <p>{{ solution.description }}</p>
            <router-link :to="toProductAdvSolveDetailPrefix+solution.articleId">
              <el-button type="primary" size="large">了解更多</el-button>
            </router-link>
          </div>
        </div>
        <div class="product-adv-container-right">
          <img :src="solution.imgPath" :alt="solution.articleTitle"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {toProductAdvSolveDetailPrefix} from "@/api/productadv";

export default {
  props: {
    solution: {
      type: Object,
      default: null
    },
  },
  setup() {
    return {toProductAdvSolveDetailPrefix}
  },
}
</script>

<style lang='less' scoped>
.product-adv-body {
  padding-left: 10vh;

  :first-child div {
    float: left;
  }

  .product-adv-container {
    padding: 3vh 0 0 0;
    width: 90%;

    .product-adv-container-left {
      width: 50%;

      .left-content {
        height: 100%;

        .product-adv-info-icon {
          font-size: 80px;
        }

        h2 {
          padding: 10px 0 10px 0;
          font-size: 30px;
          font-weight: 800;
        }

        p {
          width: 80%;
          padding-bottom: 20px;
        }

      }
    }

    .product-adv-container-right {
      width: 50%;
      height: 90%;

      img {
        max-height: 350px;
      }

    }
  }

  :after {
    content: '';
    display: block;
    clear: both;
  }
}
</style>
